jQuery.extend({

    headerPhoto: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder  : _this,
            page    : null,
            header  : null,


            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder     		= holder;
                _private.page       		= $(holder).hasClass('page');
                _private.header     		= $(holder).find('.m-header-image');
                _private.headerMobile     	= $(holder).find('.m-header-image--mobile');
                _private.headerAttr 		= _private.holder.attr('data-header');
                _private.headerMobileAttr 	= _private.holder.attr('data-header-mobile');
                _private.headerMobileData 	= JSON.parse(_private.headerMobileAttr);
                _private.headerData 		= JSON.parse(_private.headerAttr);


            },
            rondomHeader:function()
            {
                if(_private.page)
                {
                    _private.header.css('background-image', 'url(' +  _private.headerData[Math.floor(Math.random() *  _private.headerData.length)] + ')');
                    _private.headerMobile.css('background-image', 'url(' +  _private.headerMobileData[Math.floor(Math.random() *  _private.headerMobileData.length)] + ')');
                }
            }

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.rondomHeader();

        }
            // WINDOW RESIZE
        function doResize() {


        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var headerPhoto = new $.headerPhoto($("#l-wrapper.page"));
    });
});
