jQuery.extend({

	navigationMobile: function(holder){

		// PUBLIC
		var _this               = this;

		var _private = {

			// PRIVATE OBJECTS
			holder  : _this,
			page    : null,
			header  : null,


			// PRIVATE FUNCTIONS
			setup:function()
			{
				_private.holder             = holder;
				_private.mobileButton       = _private.holder.find('.js--m-navigation-mobile--trigger');
				_private.navOverlay         = _private.holder.find('.m-navigation-mobile__overlay');
				_private.navigation         = _private.navOverlay.find('.m-navigation-mobile-nav');
				_private.hasChildren        = _private.navigation.find('.menu-item-has-children');
				_private.hasChildrenLink    = _private.hasChildren.find('> a');

				$(_private.hasChildrenLink).append('<i class="fa fa-caret-down txt--white"></i>');
			},
			clickListener:function() {
				_private.mobileButton.bind('click', function() {
					_private.calcHeight();
					_private.holder.toggleClass('is--active');
					_private.navOverlay.toggleClass('is--active');
					$(this).find('i').toggleClass('fa-bars', 'fa-times');

					if($(this).hasClass('is--active')) {
						//_private.holder.css({'overflow': 'visible', 'height': 'auto'});
						_private.holder.removeClass("nav--active");
						$(this).find('i').removeClass('fa-bars');
						$(this).find('i').addClass('fa-times');
					} else {
						//_private.holder.css({'overflow': 'hidden', 'height': '100vh'});
						_private.holder.addClass("nav--active");
						$(this).find('i').removeClass('fa-cross');
						$(this).find('i').addClass('fa-times');
					}
				});

				_private.hasChildrenLink.bind('click', function(event) {
					event.stopPropagation();
					var el = $(this);
					_private.hasSubmenu(el);
					_private.calcHeight();
				});
			},
			hasSubmenu:function(el) {
				el.toggleClass('is--active');
			    $(this).next('.sub-menu').slideToggle(300);
			},
			calcHeight:function() {

				setTimeout(function(){
				  var overlayHeight = _private.navigation.height();
				  var viewportHeight = $(window).height();
				  	if(overlayHeight > viewportHeight) {
				  		_private.holder.height(overlayHeight + 120);
				  	} else if(overlayHeight < 476) {
						_private.holder.height('100vh');
					} else {
						_private.holder.height(overlayHeight + 120);
					}

				}, 310);
			},
			resetNav:function() {
				// _private.navOverlay.removeClass('is--active');
				// _private.mobileButton.find('i').removeClass('fa-times').addClass('fa-bars');
				// _private.hasChildrenLink.removeClass('is--active');
				// _private.holder.css({'overflow': 'visible', 'height': 'auto'});
				// _private.navOverlay.find('.sub-menu').slideUp();
			}

		};
		// INITIALIZE APPLICATION
		if($(holder).length > 0) {
			_private.setup();
			_private.clickListener();

		}
			// WINDOW RESIZE
		function doResize() {

			_private.resetNav();
		}
		var resizeTimer;
		$(window).resize(function() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(doResize, 100);
		});
	}
});
$(function()
{
	$(document).ready(function()
	{
		var navigationMobile = new $.navigationMobile($("#l-wrapper"));
	});
});
