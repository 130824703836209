jQuery.extend({

    mainNavigation: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder : _this,
            M001A_toggle_nav : null,
            Hassubmenu : null,

            // PRIVATE FUNCTIONS
            setup:function() {
                _private.M001A_toggle_nav = $(holder).find('.header-menu-toggle-nav > i');
                _private.Hassubmenu = $(holder).find('#header-mobile-menu li.menu-item-has-children a');
            },

            display:function() {

            },

            setProperties:function() {
                $('li.menu-item-has-children a').bind("click", function()
                {
                    $(this).next('.sub-menu').stop().slideToggle("fast");
                });

            },
            toggleNavigation:function(){
                if ($(holder).hasClass('show-nav')) {
                    $(holder).removeClass('show-nav');
                    $('#menu-hoofd-menu').slideUp("fast");
                } else {
                    $(holder).addClass('show-nav');
                    $('#menu-hoofd-menu').slideDown("fast");
               }


            },
            toggleIcon:function(){
                if ($('.header-menu-toggle-nav > i').hasClass('fa-bars')) {
                    $('.header-menu-toggle-nav > i').removeClass('fa-bars');
                    $('.header-menu-toggle-nav > i').addClass('fa-times');
                } else {
                    $('.header-menu-toggle-nav > i').removeClass('fa-times');
                    $('.header-menu-toggle-nav > i').addClass('fa-bars');
                }
            },
            toggleClick:function(){
                $('.header-menu-toggle-nav').click(function(){
                    _private.toggleNavigation();
                    _private.toggleIcon();
                });
            },
        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.display();
            _private.setProperties();
            _private.toggleClick();
        }
            // WINDOW RESIZE
        function doResize() {
            //_private.updateActions();
            _private.setProperties();
        }
        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 100);
        });
    }
});
$(function()
{
    $(document).ready(function()
    {
        var mainNavigation = new $.mainNavigation($("#Main-navigation"));
    });
});
