jQuery.extend({
    quickLinksHeader: function(holder){

        // PUBLIC
        var _this               = this;
        var _private = {

            // PRIVATE OBJECTS
            holder : _this,
            searchButton : null,
            searchfrom   : null,
            searchInput  : null,
             zoom_step:0.1,
            zoom_lvl:1,
            zoom_lvl_init:1,

            // PRIVATE FUNCTIONS
            setup:function() {
                _private.searchButton   = $(holder).find("#search-button");
                _private.searchfrom     = $(holder).find('.searchform');
                _private.searchInput    = $(holder).find('.searchbar');
                 _private.holder        = $(holder);
                  $(_private.holder).find('.zoombtn').bind("click", _private.clickZoomBtnListener);
            },
            clickSearch:function() {
                $(_private.searchButton).bind("click", function(){

                    if($(holder).hasClass("open")){
                        $(holder).removeClass("open");
                        _private.searchInput.animate({width: '0'}, 500);
                    }
                    else{
                        $(holder).addClass("open");
                        _private.searchInput.animate({width: '200'}, 500);
                        _private.searchInput.find('input').focus();
                    }

                });
            },
    
            clickZoomBtnListener:function(){
                //ZOOM IN
                if($(this).hasClass('in'))
                {
                    _private.zoom(_private.zoom_step);
                }

                //ZOOM OUT
                else if($(this).hasClass('out'))
                {
                    _private.zoom(-_private.zoom_step);
                }

                //RESET
                else
                {
                    _private.zoom(0);
                }
            },

            zoom:function(stepSize){
                if(stepSize === 0){
                    _private.zoom_lvl = _private.zoom_lvl_init;
                } else {
                    _private.zoom_lvl += stepSize;
                }
                $('body').css('zoom',_private.zoom_lvl);
                $('body').css('-moz-transform', 'scale(' + _private.zoom_lvl + ',' + _private.zoom_lvl + ')');
                $('body').css('transform-origin', '0 0');
            },

        };
        // INITIALIZE APPLICATION
        if($(holder).length > 0) {
            _private.setup();
            _private.clickSearch();
        }
    }
});
$(function()
{
    $(document).ready(function()
    {
        var quickLinksHeader = new $.quickLinksHeader($("#Quick-links-header"));
    });
});
