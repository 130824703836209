// jQuery.extend({

//     // VERSION : 130120

//     nieuwsFrontpageCarousel: function(holder){

//         // PUBLIC OBJECTS
//         var _this = this;                                                   // reference to root of class

//         var listeners = new Array();

//         var _timerTimeout               = 500;                              // timer time out
//         var _timer;                                                         //reference to timer class
//         var _timing                     = false;                            // Array for management-purposes of Events (don't change!)

//         var _private = {

//             // PRIVATE OBJECTS
//             init:null,
//             n_Newsitems:null,
//             n_timer:null,
//             previous_timer:null,
//             n_timer_max:null,

//             // PRIVATE FUNCTIONS
//             setup:function() {
//                 _private.init = true;

//                 var n = $('#Nieuws-slider li').length;

//                  // var n = Math.round((_private.n_Newsitems / 2));
//                 _private.n_timer = 0;
//                 _private.n_timer_max = n - 1;
//                 $(holder).find(".nav-prev").bind("click", _private.listenerPreviousClicked);
//                 $(holder).find(".nav-next").bind("click", _private.listenerNextClicked);

//                 // CALC WIDTH
//                 _private.n_Newsitems = $(holder).find(".nieuws-item").width();
//                 $(holder).find("#Nieuws-slider li").width($(holder).innerWidth()-30);
//                 var width_ul = _private.n_Newsitems*2;

//                 $(holder).find("#Nieuws-slider").css("width", width_ul);

//                 //INCREMENT LI NUMBER
//                 $('#Nieuws-slider li').each( function( index ) {
//                    $(this).addClass('item-' + index);
//                 });
//             },
//             setupTimer:function() {
//                 _timing = true;
//                 _timer = new jQuery.timer(_timerTimeout,_private.listenerTimerEvent);
//             },
//             playTimer:function() {
//                 _timing = true;
//                 _timer.reset(_timerTimeout);
//             },
//             stopTimer:function() {
//                 _timing = false;
//                 _timer.stop();
//             },
//             update:function(state) {
//                 _private.previous_timer = _private.n_timer;

//                 if(!_private.init) {
//                     switch(state) {
//                         case "next" :
//                             _private.n_timer++;
//                         break;
//                         case "previous" :
//                             _private.n_timer--;
//                         break;
//                     }
//                 }else{
//                     _timer.reset(8000);
//                 }

//                 if(_private.n_timer > _private.n_timer_max) {
//                     _private.n_timer = 0;
//                 }
//                 if(_private.n_timer < 0) {
//                     _private.n_timer = (_private.n_timer_max);
//                 }

//                 if(state == "next") {
//                     if(!_private.init) {
//                         _private.movePreviousLeft();
//                         _private.moveNextLeft();
//                     }else{
//                         _private.init = false;
//                         _private.fadeFirstIn();
//                     }
//                 }else{
//                     _private.movePreviousRight();
//                     _private.moveNextRight();
//                 }
//             },
//             movePreviousLeft:function() {
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.previous_timer).animate({
//                         left: '-1100px',
//                         opacity:'0'
//                      }, 600, function() {
//                  });
//             },
//             moveNextLeft:function() {
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.n_timer).animate({
//                         opacity:'0',
//                         left:'1100px'
//                      }, 0, function() {

//                          });
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.n_timer).animate({
//                         left: '0px',
//                         opacity:'1'
//                      }, 600, function() {

//                  });
//             },
//             movePreviousRight:function() {
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.previous_timer).show();
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.previous_timer).animate({
//                     left: '1000px'
//                  }, 600, function() {
//                 });
//             },
//             moveNextRight:function() {
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.n_timer).animate({
//                         opacity:'0',
//                         left:'-1100px'
//                      }, 0, function() {

//                          });
//                 $(holder).find("#Nieuws-slider").find(".item-"+_private.n_timer).animate({
//                         left: '0px',
//                         opacity:'1'
//                      }, 600, function() {

//                  });
//             },
//             fadeFirstIn:function() {
//                 $(holder).find("#Nieuws-slider").find(".item-0").animate({
//                         left: '0px',
//                         opacity:'0'
//                      }, 0, function() {

//                     });
//                 $(holder).find("#Nieuws-slider").find(".item-0").animate({
//                         opacity:'1'
//                      }, 300, function() {

//                  });
//             },

//             // LISTENERS
//             listenerTimerEvent:function() {
//                 _private.update("next");
//             },
//             listenerPreviousClicked:function() {
//                 _private.stopTimer();
//                 _private.update("previous");
//             },
//             listenerNextClicked:function() {
//                 _private.stopTimer();
//                 _private.update("next");
//             }
//         }

//         // CONSTRUCTOR
//         $.fn.nieuwsFrontpageCarousel = function() {                                         // public function constructor
//             if(!(this instanceof $.fn.nieuwsFrontpageCarousel))
//             {
//                 return $.fn.nieuwsFrontpageCarousel;
//             }
//         };

//         // PUBLIC FUNCTIONS
//         function initialize()
//         {
//             _private.setup();
//             _private.setupTimer();
//         }
//         function doResize() {
//             _private.setup();
//         }
//         var resizeTimer;
//         $(window).resize(function() {
//             clearTimeout(resizeTimer);
//             resizeTimer = setTimeout(doResize, 100);
//         });
//         // INITIALIZE APPLICATION
//         $(document).ready(function()
//         {
//             initialize();
//         });
//     }
// });
// // INITIALIZE APPLICATION
// $(function()
// {
//     var nieuwsFrontpageCarousel = new $.nieuwsFrontpageCarousel($("#Nieuws-frontpage-carousel"));
// });